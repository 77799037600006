import Q from 'q';
import _ from 'underscore';
import get_query_string_object from 'nllib/src/uri/get_query_string_object';
import check_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/check_token';
import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token";
import wangyiqiyu from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index.js'

import redirect_to_login_page from '@/lib/common-service/redirect_to_login_page';

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
        const __this = this;

        const aQueryStringObject = get_query_string_object({
            isLowerCase: true,
        });
        console.log(aQueryStringObject);

        const process_redirectType_000 = function () {
            return Q.when()
                .then(function () {
                    return check_token();
                })
                .then(function (data) {
                    const flag = data.flag;

                    if (!flag) {
                        wangyiqiyu.hide()
                        redirect_to_login_page();
                        return;
                    }

                    __this.$router.replace({
                        name: `admin-travel-book-home`,
                        params: {
                            route_guard: {
                                enable: false,
                            },
                        },
                    });
                })
                .catch(function (ex) {
                    if (!ex) return Q.reject(ex);
                    const enable_log_error = !ex.disable_log_error;
                    if (!enable_log_error) return Q.reject(ex);
                    console.error(ex);
                    console.trace();
                    if (ex.stack) console.error(ex.stack);
                    return Q.reject(ex);
                })
                ;
        };

        const process_redirectType_100 = function () {
            const token = aQueryStringObject.token;
            const backUrl = aQueryStringObject.backurl;
            const query = aQueryStringObject.query ? JSON.parse(decodeURIComponent(aQueryStringObject.query)) : {};
            if (!token) {
                const msg = 'redirect::process_redirectType_100 parameter error, code=100001';
                console.error(msg);
                return;
            }

            set_token({
                token: token,
            });

            return Q.when()
                .then(function () {
                    return check_token();
                })
                .then(function (data) {
                    const flag = data.flag;
                    if (!flag) {
                        wangyiqiyu.hide()
                        redirect_to_login_page()
                        return;
                    }
                    if (backUrl) {
                        __this.$router.replace({
                            name: backUrl,
                            query: query
                        });
                    } else {
                        __this.$router.push({
                            name: `admin-travel-book-home`,
                        });
                    }
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
                ;
        };

        const redirectType = aQueryStringObject.redirecttype;
        if (!redirectType) return process_redirectType_000();
        if (redirectType === `000`) return process_redirectType_000();
        if (redirectType === `100`) return process_redirectType_100();
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
